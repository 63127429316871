import { userApi } from 'api/user.api';
import { setCookie } from 'nookies';

import {
  finishKYCFailure,
  finishKYCSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  generateTokenFailure,
  generateTokenSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  setCurrentUser,
  signInFailure,
  signInResendFailure,
  signInResendSuccess,
  signInSuccess,
  signInVerifyFailure,
  signInVerifySuccess,
  signUpFailure,
  signUpResendFailure,
  signUpResendSuccess,
  signUpSuccess,
  signUpVerifyFailure,
  signUpVerifySuccess,
  verifyForgotPasswordFailure,
  verifyForgotPasswordSuccess,
  verifyUserKycStatusByUuidFailure,
  verifyUserKycStatusByUuidSuccess,
} from '../slices/userSlice';

import { call, put } from 'redux-saga/effects';

import { COOKIES } from 'utils/consts';

export function* signUpSaga(action: any) {
  try {
    yield call(
      async () => await userApi.signUp(action.payload)
    );
    yield put(signUpSuccess());
  } catch (error: any) {
    if (error.response)
      yield put(signUpFailure(error.response.data));
  }
}

export function* signUpResendSaga(action: any) {
  try {
    yield call(
      async () => await userApi.signUpResend(action.payload)
    );
    yield put(signUpResendSuccess());
  } catch (error: any) {
    if (error.response)
      yield put(signUpResendFailure(error.response.data));
  }
}

export function* signUpVerifySaga(action: any) {
  try {
    const { user } = yield call(
      async () => await userApi.signUpVerify(action.payload)
    );
    yield put(signUpVerifySuccess());
    yield put(setCurrentUser(user));
  } catch (error: any) {
    if (error.response)
      yield put(signUpVerifyFailure(error.response.data));
  }
}

export function* signInSaga(action: any) {
  try {
    yield call(
      async () => await userApi.signIn(action.payload)
    );
    yield put(signInSuccess());
  } catch (error: any) {
    if (error.response)
      yield put(signInFailure(error.response.data));
  }
}

export function* signInResendSaga(action: any) {
  try {
    yield call(
      async () => await userApi.signInResend(action.payload));
    yield put(signInResendSuccess());
  } catch (error: any) {
    if (error.response)
      yield put(signInResendFailure(error.response.data));
  }
}

export function* signInVerifySaga(action: any) {
  try {
    const { token, user } = yield call(
      async () => await userApi.signInVerify(action.payload)
    );
    if (token) {

      if (action.payload.next) {
        action.payload.next(token, user);
      }

      yield put(signInVerifySuccess());
      yield put(setCurrentUser(user));
    }
  } catch (error: any) {
    if (error.response)
      yield put(signInVerifyFailure(error.response.data));
  }
}

export function* generateTokenSaga(action: any) {
  try {
    const { userId, token } = yield call(
      async () => await userApi.generateToken(action.payload)
    );
    if (token) {
      // yield call(setCookie, undefined, COOKIES.JWT, token, {
      //   maxAge: 3600,
      //   path: '/buy'
      // });
      localStorage.setItem(COOKIES.JWT, token);
      yield put(generateTokenSuccess(userId));
    }
  } catch (error: any) {
    if (error.response)
      yield put(generateTokenFailure(error.response.data));
  }
}

export function* forgotPasswordSaga(action: any) {
  try {
    const { message } = yield call(
      async () => await userApi.forgotPassword(action.payload)
    );
    if (message) {
      yield put(forgotPasswordSuccess(message));
    }
  } catch (error: any) {
    yield put(forgotPasswordFailure(error));
  }
}

export function* verifyForgotPasswordSaga(action: any) {
  try {
    const { valid } = yield call(
      async () => await userApi.verifyForgotPassword(action.payload)
    );
    if (valid) {
      yield put(verifyForgotPasswordSuccess(valid));
    }
  } catch (error: any) {
    action.payload.error();
    yield put(verifyForgotPasswordFailure(error));
  }
}

export function* resetPasswordSaga(action: any) {
  try {
    const { success } = yield call(
      async () => await userApi.resetPassword(action.payload)
    );
    if (success) {
      action.payload.next();
      yield put(resetPasswordSuccess());
    }
  } catch (error: any) {
    yield put(resetPasswordFailure(error));
  }
}

export function* verifyUserKycStatusByUuidSaga(action: any) {
  try {
    const { ...data } = yield call(
      async () => await userApi.verifyUserKycStatus(action.payload)
    );
    yield put(verifyUserKycStatusByUuidSuccess(data));
    yield put(setCurrentUser(data));
  } catch (error: any) {
    console.error('error:', error);
    yield put(verifyUserKycStatusByUuidFailure(error));
    yield put(setCurrentUser(""));
  }
}

export function* ssoLoginSaga(action: any) {
  //TODO
  try {
    const { token, user } = yield call(
      async () => await userApi.ssoLogin(action.payload)
    );
    if (token) {
      if (action.payload && action.payload.next) {
        action.payload.next(token, user);
      }
    }
  } catch (error: any) {
    if (!error.response) return;
    const { reasonCode } = error.response.data;
    if (reasonCode === 10001) {
      if (action.payload && action.payload.error) {
        action.payload.goToRegister();
      }
    } else {
      if (action.payload && action.payload.error) {
        action.payload.error();
      }
    }

    yield put(signInVerifyFailure(error.response.data));
  }
}

interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}
export function* finishKYCSaga(action: any) {
  try {
    //yield put(AppActions.loading.setLoading());
    const result: ResponseGenerator = yield call(
      async () => await userApi.finishKYC(action.payload)
    );

    //yield put(AppActions.loading.finishLoading());
    yield put(finishKYCSuccess(result.data));

    if (action.payload.next) {
      action.payload.next();
    }
  } catch (error: any) {
    yield put(finishKYCFailure(error));
  }
}
