import { orderApi } from 'api';
import { AppActions } from 'store';

import { call, put } from 'redux-saga/effects';

export function* createOrderSaga(action: any) {
  try {
    const { pending, error, next, ...payload } = action.payload;
    const { ...data } = yield call(orderApi.createOrder, payload);
    if (data.status === 'APPROVED') {
      yield put(AppActions.order.createOrderSuccess(data));

      if (action.payload.next) {
        action.payload.next();
      }
    } else if (
      data.statusCode === 400 &&
      data.body.status === 'TRAVEL_RULE_ADDITIONAL_INFORMATION'
    ) {
      if (action.payload.travelRuleConfirm) {
        action.payload.travelRuleConfirm();
      }
    } else {
      yield put(AppActions.order.createOrderFailure(data));

      if (data.status === 'PENDING' && action.payload.pending) {
        action.payload.pending();
      }

      if (
        data.body &&
        (data.body.data.errorCode === 5002 || data.body.data.errorCode === 5001)
      ) {
        action.payload.error(data.body.data.message);
      }
    }
  } catch (error: any) {
    if (error.response) {
      yield put(AppActions.order.createOrderFailure(error.response.data));
    } else {
      yield put(AppActions.order.createOrderFailure(error));
      if (action.payload.error) {
        action.payload.error(
          'It has not been possible to place your Order at this time. Please try again or contact the Customer Support'
        );
      }
    }
  }
}

export function* createOffRampOrderSaga(action: any) {
  try {
    const { error, ...payload } = action.payload;
    const { ...data } = yield call(
      async () => await orderApi.createOffRampOrder(payload)
    );
    if (data.status === 'APPROVED') {
      yield put(AppActions.order.createOrderSuccess(data));
    } else {
      yield put(AppActions.order.createOrderFailure(data));
      if (
        data.body &&
        (data.body.data.errorCode === 5002 || data.body.data.errorCode === 5001)
      ) {
        action.payload.error(data.body.data.message);
      } else {
        action.payload.error('It has not been possible to place your Order at this time. Please try again or contact the Customer Support');
      }
    }
  } catch (error: any) {
    action.payload.error('It has not been possible to place your Order at this time. Please try again or contact the Customer Support');
    if (error.response) {
      yield put(AppActions.order.createOrderFailure(error.response.data));
    }
  }
}

export function* getOrdersSaga(action: any) {
  try {
    const { ...data } = yield call(
      async () => await orderApi.getOrders(action.payload)
    );
    if (data) {
      yield put(AppActions.order.getOrdersSuccess(data));
    }
  } catch (error: any) {
    if (error.response) {
      yield put(AppActions.order.getOrdersFailure(error.response.data));
    }
  }
}

export function* getUnCompletedOrdersSaga(action: any) {
  try {
    const { ...data } = yield call(
      async () => await orderApi.getUncompletedOrders()
    );
    if (data) {
      yield put(AppActions.order.getUncompletedOrdersSuccess(data));
    }
  } catch (error: any) {
    if (error.response) {
      yield put(
        AppActions.order.getUncompletedOrdersFailure(error.response.data)
      );
    }
  }
}

export function* updateOrderSaga(action: any) {
  try {
    const { next, ...rest } = action.payload;
    const { ...data } = yield call(
      async () => await orderApi.updateOrder(rest)
    );
    next(data.order);
    yield put(AppActions.order.updateOrderSuccess(data));
  } catch (error: any) {
    if (error.response) {
      yield put(AppActions.order.updateOrderFailure(error.response.data));
    }
  }
}

export function* getOrderSaga(action: any) {
  try {
    const { ...data } = yield call(
      async () => await orderApi.getOrder(action.payload)
    );
    yield put(AppActions.order.getOrderSuccess(data));
  } catch (error: any) {
    if (error.response) {
      yield put(AppActions.order.getOrderFailure(error.response.data));
    }
  }
}
