import { getClient } from './client';

export const customerServerApiClient = getClient(
  process.env.REACT_APP_SERVER_API || ''
);
export const lambdaServerApiClient = getClient(
  process.env.REACT_APP_LAMBDA_API || ''
);
export const saasUserAPiClient = getClient(
  process.env.REACT_APP_SAAS_USER_API || ''
);

export const apiServerClient = getClient(
  'https://api.dev.pg.blockdance.io/'
);