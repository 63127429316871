import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import SelectButton from 'components/common/buttons/SelectButton';
import Button from 'components/common/buttons/Button'
import Header from 'components/common/header/Header'
import Input from 'components/common/inputs/Input'
import { RootState } from 'store';
import { flagImages, merchantId, PATHS } from 'utils/consts';
import { checkCreditCard, getExpiryData } from 'utils/cards';
import SelectCountryPopup from 'components/common/popups/SelectCountryPopup';
import { PlatformContext } from 'contexts';
import { CancelInCircleSvg } from 'utils/icons';
import { useNavigate } from 'react-router-dom';

type CardInput = {
  cardNumber: string;
  cardHolderName: string;
  expiryDate: string;
  cvv: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state?: string;
  zipCode: string;
};

const schema = yup
.object()
.shape({
  cardNumber: yup.string().required(),
  cardHolderName: yup.string().required(),
  expiryDate: yup.string().required(),
  cvv: yup.string().required(),
  addressLine1: yup.string().required(),
  city: yup.string().required(),
  zipCode: yup.string().required(),
});

export const CardPayment = () => {
  const { register, handleSubmit } = useForm<CardInput>({
    resolver: yupResolver(schema),
  });
  const { countries } = useSelector((root: RootState) => root.country);
  const { saveCardDetail } = useContext(PlatformContext);
  const [country, setCountry] = useState<string>(countries[0]?.code);
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const onSubmit = async (data: CardInput) => {
    const {
      cardNumber,
      cardHolderName,
      expiryDate,
      cvv,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
    } = data;

    const cardTypeData = checkCreditCard(cardNumber);
    const cardScheme = cardTypeData.type;
    const cardType = "CREDIT";

    // check card expiry date
    const cardExpiryData = getExpiryData(expiryDate);

    if (!cardExpiryData) {
      setErrorMessage('Card expiry date is not correct! Please input correct one!');
    }

    const { expiryMonth, expiryYear } = cardExpiryData as any;

    saveCardDetail({
      cardInfo: {
        cardNumber,
        cardScheme,
        cardType,
        expiryMonth,
        expiryYear,
        cvv,
      },
      billingAddress: {
        addressLine1: addressLine1,
        addressLine2: addressLine2 || undefined,
        city: city,
        state: state || undefined,
        zip: zipCode,
        country: country,
      },
      cardHolderName,
    });

    navigate(`${PATHS.BUY}${PATHS.CONFRIM_ORDER}`);
  };

  const onCloseWarning = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Header title={`Enter Card Info`} />
      {
        !!errorMessage &&
        <div className="flex items-center bg-feedback-warning rounded-xl mx-4 px-4 py-2 mb-4" onClick={onCloseWarning}>
          <img src={CancelInCircleSvg} />
          <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
        </div>
      }
      <form className='p-4 w-full' onSubmit={handleSubmit(onSubmit)}>
        <Input className='mt-2' label='Card Number' name='cardNumber' register={register} />
        <Input className='mt-2' label='Card Holder Name' name='cardHolderName' register={register} />
        <div className='flex mt-2'>
          <Input className='w-full mr-4' label='Expiry Date (MM / YY)' name='expiryDate' register={register} />
          <Input className='w-full' label='CVV' name='cvv' register={register} />
        </div>


        <Input className='mt-8' label='Address Line 1' name='addressLine1' register={register} />
        <Input className='mt-2' label='Address Line 2' name='addressLine2' register={register} />
        <Input className='mt-2' label='City' name='city' register={register} />
        <Input className='mt-2' label='State' name='state' register={register} />
        <Input className='mt-2' label='Zipcode' name='zipCode' register={register} />
        <SelectButton
          className="mt-2"
          label="Country"
          text={countries.find(el => el.code === country)?.name}
          svgPath={flagImages[country]}
          onClick={() => setOpenCountrySelect(true)}
          flag
        />
        <Button className='w-full mt-4' type='submit'>
          Submit
        </Button>
      </form>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={country}
          onChange={(value) => {
            setCountry(value);
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  )
}