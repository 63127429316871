import { useMemo, useState } from "react";
import { SearchInCircleSvg, SearchSvg } from "utils/icons";
import { ICurrency } from "types";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { getCurrencySvg } from "utils/consts";

interface IProps {
  className?: string,
  currency: string,
  type: number,
  onChange?: (currency: ICurrency) => void,
}

export const SelectCurrency: React.FC<IProps> = (props) => {
  const { className, currency, type, onChange } = props;
  const [filter, setFilter] = useState<string>("");

  const { currencies, offRampCurrencies } = useSelector((root: RootState) => root.currency);

  const onChangeFilter = (e: any) => {
    setFilter(e.target.value);
  };

  const onChangeFiat = (currency: ICurrency) => {
    onChange && onChange(currency);
  };

  const _currencies = useMemo(() => {
    if (type)
      return offRampCurrencies.filter(currency => ((currency.name && currency.name.includes(filter)) || currency.code.includes(filter)));
    return currencies.filter(currency => ((currency.name && currency.name.includes(filter)) || currency.code.includes(filter)));
  }, [filter, type])

  return (
    <>
      <div className={`relative flex w-full ${className}`}>
        <img className="absolute left-4 top-4" src={SearchSvg} />
        <input
          className="block p-4 pl-12 w-full text-base font-medium text-font-primary bg-surface-secondary rounded-xl border border-surface-secondary outline-none focus:ring-primary focus:border-primary duration-200"
          onChange={onChangeFilter}
        />
      </div>
      <div className="overflow-auto flex-grow">
        {
          _currencies.length ? _currencies.map((el: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center mt-4 p-2 ${el.code === currency && 'bg-feedback-information-tint'}`}
                onClick={() => { onChangeFiat(el); }}
              >
                <div className="w-8 h-8 rounded-full bg-center bg-cover mr-4" style={{ backgroundImage: `url(${getCurrencySvg(el.code)})` }} />
                <div>
                  <div className="text-base font-medium text-font-primary">{el.name}</div>
                  <div className="text-sm font-medium text-gray-500">{el.code}</div>
                </div>
              </div>
            );
          })
            : filter && (
              <div className="flex flex-col justify-content items-center p-12">
                <img src={SearchInCircleSvg} />
                <span className="text-base font-medium text-font-primary mt-4">We do not support this currency yet!</span>
              </div>
            )
        }
      </div>
    </>
  );
};

export default SelectCurrency;